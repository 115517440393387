// @flow
// in src/posts.js
import React from "react";
import { ShowView, ShowController, SimpleShowLayout, TextField, DateField, SelectField, UrlField } from "react-admin";

// email: string;
// name: string;
// lastName?: string;
// fatherName?: string;
// dateOfBirth?: string;
// city?: string;
// socialLink?: string;
// about?: string;
// accessLevel?: string;

const UserShow = (props) => (
  <ShowController {...props} >
    {controllerProps =>
      <ShowView {...props} {...controllerProps} title={"Пользователь " + controllerProps?.record?.email}>
        <SimpleShowLayout>
          <TextField source="email" label="Email пользователя" />
          <TextField source="name" label="Имя" />
          {controllerProps?.record?.fatherName && (
            <TextField source="lastName" label="Фамилия" />
          )}
          {controllerProps?.record?.fatherName && (
            <TextField source="fatherName" label="Отчество" />
          )}
          {controllerProps?.record?.dateOfBirth && (
            <DateField source="dateOfBirth" label="Дата рождения" />
          )}
          {controllerProps?.record?.phoneNumber && (
              <TextField source="phoneNumber" label="номер телефона" />
          )}
          {controllerProps?.record?.city && (
            <TextField source="city" label="Город" />
          )}
          {controllerProps?.record?.socialLink && (
            <UrlField source="socialLink" label="Ссылка в соцсетях" />
          )}
          {controllerProps?.record?.about && (
            <TextField source="about" label="О себе" multiline />
          )}
          <SelectField
            label="Права"
            source="role"
            choices={[
              { id: "SADMIN", name: "Админ" },
              { id: "USER", name: "Пользователь" },
            ]}
          />
          <DateField label="Дата создания" source="createdAt" />
          <DateField label="Дата редактирования" source="updatedAt" />
        </SimpleShowLayout>
      </ShowView>
    }
  </ShowController>
);

export default UserShow;
