// @flow
import React from "react";
import {
  ShowView, SimpleShowLayout, TextField, DateField,
  UrlField,
  ShowController, SelectField, ArrayField,
  Datagrid
} from "react-admin";
import { RECOMMENDED_PLAYLISTS_NAMES } from '../consts'


const PlaylistShow = (props /*: Object */) => (
  <ShowController {...props} >
    {controllerProps =>
      <ShowView {...props} {...controllerProps} title={"Рекомендованный Плейлист"}>
        <SimpleShowLayout>
          <SelectField
            label="Тип"
            source="type"
            choices={RECOMMENDED_PLAYLISTS_NAMES}
          />
          <ArrayField label="Видео" source="videos">
            <Datagrid>
              <TextField label="название" source="name" />
              <TextField label="videoId" source="videoId" />
              <UrlField label="Ссылка доступа" source="link" />
            </Datagrid>
          </ArrayField>
          <DateField label="Дата создания" source="createdAt" />
          <DateField label="Дата редактирования" source="updatedAt" />
        </SimpleShowLayout>
      </ShowView>
    }
  </ShowController>
);

export default PlaylistShow;
