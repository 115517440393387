// @flow
import React from "react";
import { ShowView, SimpleShowLayout, ShowController, TextField, DateField, NumberField, FunctionField, BooleanField, SelectField, RichTextField, ImageField } from "react-admin";
import { CONTESTS_FORMATS_NAMES } from '../consts'

const ContestShow = (props /*: Object */) => (
  <ShowController {...props}>
    {controllerProps =>
      <ShowView {...props} {...controllerProps}>
        <SimpleShowLayout>
          <TextField label="Название" source="name" />
          <RichTextField label="Описание" source="description" />
          <ImageField label="Фото" source="img"></ImageField>
          <DateField label="Дата начала" source="dateStart" />
          {controllerProps?.record?.isRange &&
            <DateField label="Дата окончания" source="dateFinish" />
          }
          <TextField label="Внешний конкурс" source="externalLink" />
          <NumberField label="Цена" source="price" />
          <BooleanField label="Интервал" source="isRange" />
          <BooleanField label="Первая позиция" source="isTop" />
          <BooleanField label="Вторая позиция" source="isTop2" />
          <BooleanField label="Опубликован" source="isPublished" />
          <SelectField
            label="Формат"
            source="format"
            choices={CONTESTS_FORMATS_NAMES}
          />
        </SimpleShowLayout>
      </ShowView>
    }
  </ShowController>
);

//   _id: string;
//   name: string;
//   description: string;
//   img: string;
//   dateStart: Date;
//   dateFinish?: Date;
//   price?: number;
//   format: string;
//   externalLink?: string;
//   isTop?: boolean;
//   isTop2?: boolean;
//   isPublished: boolean;

export default ContestShow;
