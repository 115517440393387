// @flow
import React from "react";
import { SimpleForm, TextInput, NumberInput, DateInput, Edit, Create, required, ReferenceInput, SelectInput } from "react-admin";

export const CouponEdit = (props /*: Object */) => (
  <Edit {...props}>
    <Form {...props} />
  </Edit>
);

export const CouponCreate = (props /*: Object */) => (
  <Create {...props}>
    <Form {...props} />
  </Create>
);

const Form = (props /*: Object */) => (
  <SimpleForm {...props} redirect="show">
    <TextInput label="Название" source="name" validate={[required()]} fullWidth />
    <TextInput label="Код промокода" source="code" format={codeFormatter} validate={[required()]} fullWidth />
    <GoodId></GoodId>
    <NumberInput label="Процент скидки" source="percent" format={numberFormatter} validate={[required()]} fullWidth />
    <NumberInput label="Максимальное кол-во использований" source="maxUseCount" fullWidth />
    <DateInput label="Дата истечения" source="expiryDate" validate={[required()]} fullWidth />
  </SimpleForm>
);

const codeFormatter = (value) => {
  if (value) {
    return value.replace(" ", "");
  }
  return value;
};

const numberFormatter = (v) => {
  if (v) {
    if (v > 100) return 100;
    if (v <= 0) return 1;
  }
  return v;
};


const GoodId = () => {
  return (
    <ReferenceInput label="Товар" source="goodId" reference="admin/goods" >
      <SelectInput optionText="name" resettable fullWidth />
    </ReferenceInput>
  )
}