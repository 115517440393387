import React from "react";
import {
  ImageInput, ImageField, ReferenceInput, SelectInput, ReferenceArrayInput, SelectArrayInput, ArrayInput, SimpleFormIterator,
  SimpleForm, TextInput, BooleanInput, Edit, Create, required, maxLength, NumberInput
} from "react-admin";
import { CONTESTS_FORMATS_NAMES } from '../consts'
import RichTextInput from 'ra-input-rich-text';


// _id: ObjectId;
// name: string;
// latinName: string;
// description: string;
// lessonsBlocks: {
//   name?: string;
//   lessonsIds: ObjectId[];
//   lessons?: Lesson[];
// }[];
// webinarsCount?: number;
// goodId: ObjectId;
// good?: Good;
// preview: {
//   description: string;
//   teaser: string;
//   contentId?: string;
//   img: string;
// };;
// announce: {
//   description: string;
//   img: string;
// };
// teachersIds: ObjectId[];
// duration: number;
// isPublished: boolean;
// baner?: {
//   sale: number;
//   name: string;
//   description?: string;
// };
// organizer?: string;


export const Form = (props /*: Object */) => (
  <SimpleForm {...props} redirect="list" >
    <TextInput label="Название" source="name" validate={[required()]} fullWidth />
    <SelectInput
      label="Направление"
      source="fieldOfStudy"
      choices={CONTESTS_FORMATS_NAMES}
      allowEmpty
      resettable
    />
    <RichTextInput label="Для кого курс"
      source="description" validate={[required()]}
      options={{ formats: ["align", "list", "header", "direction", "underline", "link", "bold", "Italic"] }}
    />
    <RichTextInput label="О курсе"
      source="aboutText"
      options={{ formats: ["align", "list", "header", "direction", "underline", "link", "bold", "Italic"] }}
    />
    <Preview></Preview>
    <Announce></Announce>
    <GoodId name="goodId" label="Товар" />
    <GoodId name="lightGoodId" label="Товар без ДЗ"/>
    <LessonsBlocs />
    <TeachersInput></TeachersInput>
    <TextInput label="Ссылка на первый урок" source="firstLessonLink" fullWidth />
    <BooleanInput label="Электронные материалы" source="elkMaterials"></BooleanInput>
    <NumberInput label="Кол-во дз" source="homeworksCount"></NumberInput>
    <NumberInput label="Продолжительность" source="duration"></NumberInput>
    <TextInput label="Дата начала" source="startDate" fullWidth />
    <Baner />
    <BooleanInput label="Опубликовать" source="isPublished" />
    <Reviews></Reviews>
    <FAQ></FAQ>
    <TextInput label="Id шаблона в Send Pulse" source="email.tmpId" fullWidth />
    <TextInput label="тема письма" source="email.subject" fullWidth />

  </SimpleForm>
)

export const Preview = () => {
  return (
    <>
      <TextInput label="Превью видео" source="preview.teaser" fullWidth />
      <RichTextInput label="Что вы получите в итоге"
        source="preview.description" validate={[required()]}
        options={{ formats: ["align", "list", "header", "direction", "underline", "link", "bold", "Italic"] }}
      />
      <ImageInput label="Картинка страницы о курсе" source="preview.img" accept="image/jpeg" maxSize={300000} labelSingle="ОТОБРАЖАЕТСЯ, ЕСЛИ НЕ ДОБАВЛЕН ТИЗЕР КУРСА Одно изображение в формате JPEG до 300 КБ">
        <ImageField source="preview.img" title="изображение" />
      </ImageInput>
    </>
  )
}

export const Announce = () => {
  return (
    <>
      <TextInput
        label="Описание анонса (225 знаков)"
        multiline
        source="announce.description"
        validate={[required(), maxLength(255)]}
        length={5}
        fullWidth
      />
      <ImageInput label="Картинка анонса" source="announce.img" validate={[required()]} accept="image/jpeg" maxSize={300000} labelSingle="Одно изображение в формате JPEG до 300 КБ">
        <ImageField source="announce.img" title="изображение" />
      </ImageInput>
      <ImageField source="announce.img" title="изображение" />

    </>
  )
}

export const GoodId = ({ name, label, validate}) => {
  return (
    <ReferenceInput validate={validate || []} label={label} source={name} reference="admin/goods" >
      <SelectInput optionText="name" resettable fullWidth />
    </ReferenceInput>
  )
}

export const LessonsBlocs = () => {
  return (
    <ArrayInput source="lessonsBlocks" label="Блоки уроков">
      <SimpleFormIterator>
        <TextInput label="Название" source="name" fullWidth validate={required()} />
        <ReferenceArrayInput label="Уроки" source="lessonsIds" reference="admin/lessons" perPage={200} fullWidth sort={{ field: 'name', order: 'ASC' }}>
          <SelectArrayInput optionText="name" validate={required()} />
        </ReferenceArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export const TeachersInput = () => {
  return (
    <ReferenceArrayInput label="Преподаватели" source="teachersIds" perPage={200} reference="admin/persons" fullWidth>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
  )
}

export const Baner = () => {
  return (
    <>
      <NumberInput label="Банер Скидка" source="baner.sale" fullWidth />
      <TextInput label="Банер Заголовок" source="baner.name" fullWidth />
      <TextInput label="Банер Описание" source="baner.description" fullWidth />
    </>
  )
}

export const Reviews = () => {
  return (
    <>
      <ArrayInput source="reviews">
        <SimpleFormIterator>
          <TextInput label="Заголовок" source="title" fullWidth />
          <RichTextInput label="Описание"
            source="text" validate={[required()]}
            options={{ formats: ["align", "list", "header", "direction", "underline", "link", "bold", "Italic"] }}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

const FAQ = () => {
  return (
      <ArrayInput source="faq">
        <SimpleFormIterator>
          <TextInput label="Заголовок" source="title" validate={[required()]} fullWidth />
          <TextInput label="Описание" fullWidth
                  multiline
            source="text" validate={[required()]}
          />
        </SimpleFormIterator>
      </ArrayInput>
  )
}

// preview: {
//   description: string;
//   teaser: string;
//   contentId?: string;
// };;



export const CoursesEdit = (props /*: Object */) => (
  <Edit {...props}>
    <Form {...props} />
  </Edit>
);

export const CoursesCreate = (props /*: Object */) => (
  <Create {...props}>
    <Form {...props} />
  </Create>
);