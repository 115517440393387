// @flow
import React from "react";
import { Show, SimpleShowLayout, TextField, DateField, NumberField, SelectField, FunctionField } from "react-admin";
const PAYMENT_TYPES = {
  NEW: "NEW",
  INIT: "INIT",
  AUTHORIZED: "AUTHORIZED",
  COMPLETE: "COMPLETE",
  REJECTED: "REJECTED",
};

const ItemShow = (props /*: Object */) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="Товар" source="good.name" sortable={false} />
      <FunctionField label="Цена" render={(record /*: Object */) => `₽ ${record.price / 100}`} sortable={false} />
      <SelectField
        source="status"
        choices={[
          { id: PAYMENT_TYPES.NEW, name: "Новый" },
          { id: PAYMENT_TYPES.INIT, name: "Инициирован" },
          { id: PAYMENT_TYPES.AUTHORIZED, name: "Авторизован" },
          { id: PAYMENT_TYPES.COMPLETE, name: "Успешен" },
          { id: PAYMENT_TYPES.REJECTED, name: "Ошибка" },
        ]}
      />
      <TextField source="user.email" label="Email пользователя" sortable={false} />
      <TextField source="user.name" label="Имя пользователя" sortable={false} />
      <NumberField source="coupon.name" label="Промокод" sortable={false} />
      <NumberField source="coupon.percent" label="Процент скидки" options={{ style: "percent" }} sortable={false} />
      <TextField source="paymentType" label="Тип оплаты" sortable={false} />
      <DateField showTime label="Дата создания" source="createdAt" />
      <DateField showTime label="Дата обновления" source="updatedAt" sortable={false} />
    </SimpleShowLayout>
  </Show>
);

export default ItemShow;
/*
  _id: Types.ObjectId;
  price: number;
  goodId: Types.ObjectId;
  status: string;
  userId: User["_id"];
  paymentURL?: string;
  receipt: object;
  paymentType: string;
  paymentProviderId?: string;
  createdAt: Date;
  updatedAt: Date;
  errorMessage?: string;
  couponId?: Types.ObjectId;
*/
