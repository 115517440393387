// @flow
import React from "react";
import { SimpleForm, TextInput, NumberInput, Edit, Create, required, SelectInput, FormDataConsumer } from "react-admin";
import { GOODS_NAMES, SCENARIO_NAMES, GOOD_TYPES } from '../consts'

export const FormEdit = (props /*: Object */) => (
  <Edit {...props}>
    <Form {...props} />
  </Edit>
);

export const FormCreate = (props /*: Object */) => (
  <Create {...props}>
    <Form {...props} />
  </Create>
);

const Form = (props /*: Object */) => (
  <SimpleForm {...props} redirect="list">
    <TextInput fullWidth label="Название" source="name" validate={[required()]} />
    <NumberInput fullWidth label="Цена" source="price" format={numberFormatter} parse={priceParser} validate={[required()]} />
    <SelectInput
        fullWidth
      source="type"
      label="Тип"
      validate={[required()]}
      choices={GOODS_NAMES}
    />
    <ScenarioType />
  </SimpleForm>
);

const ScenarioType = () => {
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        if (formData && formData.type && formData.type === GOOD_TYPES.SCENARIO_REVIEW) {
          return (
            <SelectInput
              {...rest}
              source="code"
              label="Тип сценария"
              validate={[required()]}
              choices={SCENARIO_NAMES}
              fullWidth
            />
          )
        }
        return undefined
      }}
    </FormDataConsumer>
  )
}

const priceParser = (v) => {
  if (v) {
    return v * 100;
  }
  return v;
};

const numberFormatter = (v) => {
  if (v) {
    return v / 100;
  }
  return v;
};


