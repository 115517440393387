// @flow
import React from "react";
import {
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  TextInput,
} from "react-admin";

import { uploadFile as upload } from '../AddDataProvider'

const PostTitle = ({ record }) => {
  return <span>{`Загрузка ответа на "${record.name}"`}</span>;
};


// _id: ObjectId;
// name: string;
// lessonId: ObjectId;
// userId: ObjectId;
// courseId?: ObjectId;
// uploadUrl: string;
// response?: string;
// responseName?: string;

const EditHomework = props => (
  <Edit title={<PostTitle />} {...props} transform={uploadFile}>
    <SimpleForm>
      <TextInput source="responseName" title="Название" fullWidth></TextInput>
      <FileInput source="file" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);


const uploadFile = async (data) => {
  const response = await upload(data.file, "reviews");
  return {
    ...data,
    file: undefined,
    response
  }
};

export default EditHomework;