// @flow
import { stringify } from "query-string";
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, DELETE, fetchUtils } from "react-admin";
const { fetchJson } = fetchUtils;

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const createRestClient = (apiUrl, httpClient = fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertRESTRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {
      credentials: "include",
    };
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const filterQuery = params.filter || {};
        const query = {
          offset: page * perPage - perPage,
          limit: perPage,
          sort: params.sort.field,
          sortOrder: params.sort.order,
          ...filterQuery,
          // range: JSON.stringify([
          //   (page - 1) * perPage,
          //   page * perPage - 1,
          // ]),
          // filter: JSON.stringify(params.filter),
        };
        console.log(query, params.pagination)
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_ONE:
        if (!params.id || params.id === "undefined") break;
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case GET_MANY: {
        const query = {
          ids: params.ids.join(","),
          offset: 0,
          limit: 1000,
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case UPDATE:
        const { data, previousData } = params;
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "PUT";
        options.body = JSON.stringify({ ...previousData, ...data });
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);

        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} REST response
   */
  const convertHTTPResponseToREST = (response, type, resource, params) => {
    const { json } = response;

    switch (type) {
      case GET_LIST:
        return {
          data: json.data,
          total: json.total || json.data.length,
        };
      case GET_MANY_REFERENCE:
      case GET_MANY:
        return {
          data: json.data,
          total: json.total || json.data.length,
        };
      case GET_ONE:
        return { data: json.data };
      case UPDATE:
        return { data: json.data };
      case CREATE:
        return { data: json.data };
      default:
        return { data: json };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  return (type, resource, params = {}) => {
    const { url, options } = convertRESTRequestToHTTP(type, resource, params);
    if (!url) return {};
    return httpClient(url, options).then((response) => convertHTTPResponseToREST(response, type, resource, params));
  };
};

export default createRestClient(process.env.REACT_APP_API_BASE_URL);
