// @flow
import React from "react";
import {
  ShowView, SimpleShowLayout, TextField, DateField,
  NumberField, BooleanField, ImageField, UrlField,
  ChipField, ShowController, FunctionField, ArrayField,
  Datagrid, RichTextField
} from "react-admin";

const PlaylistShow = (props /*: Object */) => (
  <ShowController {...props} >
    {controllerProps =>
      <ShowView {...props} {...controllerProps} title={"Плейлист " + controllerProps?.record?.name}>
        <SimpleShowLayout>
          <TextField label="Название" source="name" />
          <RichTextField label="Описание" source="description" />
          <TextField label="Описание анонса" source="announceDescription"></TextField>
          <NumberField label="Порядковый номер" source="order" />
          <BooleanField label="Опубликован" source="isPublished" ></BooleanField>
          <FunctionField label="Платный" render={(record /*: Object */) => record.goodId ? "Да" : "Нет"} />
          {controllerProps.record?.good && (
            <TextField label="Название товара" source="good.name" />
          )}
          {controllerProps.record?.good && (
            <FunctionField label="Цена" render={(record /*: Object */) => `₽ ${record.good.price / 100}`} />
          )}
          <ImageField label="Фото" source="img"></ImageField>
          <UrlField label="Трейлер" source='trailer'></UrlField>
          {controllerProps?.record?.duration && (
            <FunctionField label="Продолжительность" render={(record /*: Object */) => `${Math.floor(record.duration / 3600)} часов`} />
          )}
          <ArrayField label="Видео" source="videos">
            <Datagrid>
              <TextField label="название" source="name" />
              <TextField label="videoId" source="videoId" />
              <UrlField label="Ссылка доступа" source="link" />
            </Datagrid>
          </ArrayField>
          {controllerProps?.record?.additionalVideos?.length && (
            <ArrayField label="Рекомендованные видео" source="additionalVideos" >
              <Datagrid>
                <TextField label="название" source="name" />
                <TextField label="videoId" source="videoId" />
                <UrlField label="Ссылка доступа" source="link" />
              </Datagrid>
            </ArrayField>
          )}
          {controllerProps?.record?.teachers?.length && (
            <ArrayField label="Преподаватели" source="teachers" >
              <Datagrid>
                <TextField label="имя" source="name" />
              </Datagrid>
            </ArrayField>
          )}
          <BooleanField label='Рейтинг' source='withRating' />
          <BooleanField label="Мираж плейлист" source="isMiraz" />
          {controllerProps?.record?.tags?.length && (
            <ChipField label="Теги" source="tags" />
          )}
          {controllerProps?.record?.partners?.length && (
            <ArrayField source="partners">
              <Datagrid>
                <TextField label="Партнеры" source="name" />
                <TextField label="Партнеры" source="description" />
              </Datagrid>
            </ArrayField>
          )}
          <DateField label="Дата создания" source="createdAt" />
          <DateField label="Дата редактирования" source="updatedAt" />
        </SimpleShowLayout>
      </ShowView>
    }
  </ShowController>
);


// type Playlist = {
//   _id: Types.ObjectId;
//   name: string;
//   videosIds: Types.ObjectId[];
//   videos?: Video[];
//   goodId?: Types.ObjectId;
//   paid: boolean;
//   trailer: string;
//   duration?: number;
//   img: string;
//   description: string;
//   teachers: string[];
//   partners: string[];
//   tags: string[];
//   order: number;
//   createdAt: Date;
//   updatedAt: Date;
//   isPublished?: boolean;
// };

export default PlaylistShow;
