/* eslint-disable react/display-name */
// @flow
import React from "react";
import { List, Datagrid, TextField, DateField, ShowButton, EditButton, TextInput, Filter } from "react-admin";

const PostFilter = (props) => (
  <Filter {...props} fullWidth>
    <TextInput label="имя" source="name" alwaysOn fullWidth resettable />
  </Filter>
);

/* we use perPage={10000} because we don't want to use pagination here. */
export default (props) => (
  <List title="Все Видео" {...props} perPage={30} bulkActionButtons={null} filters={<PostFilter {...props} />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="videoId" label="videoId" />
      <DateField label="Дата редактирования" source="updatedAt" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);
