import React from "react";
import { SimpleForm, TextInput, BooleanInput, Edit, Create, required, maxLength } from "react-admin";

export const BanerEdit = (props /*: Object */) => (
  <Edit {...props}>
    <Form {...props} />
  </Edit>
);

export const BanerCreate = (props /*: Object */) => (
  <Create {...props}>
    <Form {...props} />
  </Create>
);

const Form = (props /*: Object */) => (
  <SimpleForm {...props}>
    <TextInput label="Заголовок" source="header" validate={[required(), maxLength(40)]} fullWidth />
    <TextInput label="Подзаголовок" source="subheader" validate={[required(), maxLength(53)]} fullWidth />
    <TextInput label="Текст" source="text" fullWidth validate={[maxLength(53)]} />
    <TextInput label="Ссылка" source="link" fullWidth ></TextInput>
    <BooleanInput label='Опубликован' source='isPublished' />
  </SimpleForm>
);