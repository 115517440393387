// @flow
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  // AUTH_GET_PERMISSIONS,
  CREATE,
} from "react-admin";
import restProvider from "./restProvider";

export default (type, params) => {
  switch (type) {
    case AUTH_CHECK: {
      return window.localStorage.getItem("isLoggedIn")
        ? Promise.resolve()
        : Promise.reject(new Error("AUTH_CHECK ERROR"));
    }
    case AUTH_LOGIN: {
      window.localStorage.setItem("isLoggedIn", true);
      return restProvider(CREATE, "auth/login", {
        data: {
          email: params.username,
          password: params.password,
        },
      });
    }
    case AUTH_LOGOUT: {
      window.localStorage.removeItem("isLoggedIn");
      restProvider(CREATE, "logout");
      return Promise.resolve();
    }
    case AUTH_ERROR: {
      return Promise.resolve();
    }
    default:
      return Promise.resolve();
  }

  // return Promise.resolve(false);
};
