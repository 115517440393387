// @flow
/* eslint-disable react/display-name */
import React from "react";
import { List, Datagrid, TextField, FunctionField } from "react-admin";

/* we use perPage={10000} because we don't want to use pagination here. */
export default (props /*: Object */) => (
  <List title="Все Рейтинги" {...props} perPage={10000} bulkActionButtons={null}>
    <Datagrid>
      <FunctionField label="Название" render={(record /*: Object */) => record.videoName[0]} />
      <TextField label="Рейтинг" source="rating" />
      <TextField label="Количество голосовавших" source="count" />
    </Datagrid>
  </List>
);

/*
  type: GoodType;
  name: string;
  price: number;
  createdAt?: Date;
  updatedAt?: Date;
  _id: Types.ObjectId;
  code?: string;
*/
