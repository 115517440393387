// @flow
import React from "react";
import { List, Datagrid, TextField, DateField, ShowButton } from "react-admin";

/* we use perPage={10000} because we don't want to use pagination here. */
export default (props) => (
  <List title="Все Трансляции" {...props} perPage={10000} bulkActionButtons={null}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="videoId" />
      <TextField source="link" />
      <DateField showTime source="startDate" />
      <DateField showTime label="Дата создания" source="createdAt" />
      <DateField showTime label="Дата редактирования" source="updatedAt" />
      <ShowButton />
    </Datagrid>
  </List>
);
