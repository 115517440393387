// @flow
/* eslint-disable react/display-name */
import React from "react";
import { List, Datagrid, TextField, EditButton, ImageField } from "react-admin";

/* we use perPage={10000} because we don't want to use pagination here. */
export default (props /*: Object */) => (
  <List title="Все персоны" {...props} perPage={10000} bulkActionButtons={null}>
    <Datagrid>
      <TextField label="Название" source="name" />
      <TextField label="Описание" source="description" />
      <ImageField source="img" title="изображение" />
      <EditButton />
    </Datagrid>
  </List>
);

/*
  type: GoodType;
  name: string;
  price: number;
  createdAt?: Date;
  updatedAt?: Date;
  _id: Types.ObjectId;
  code?: string;
*/
