// @flow
// in src/posts.js
import React from "react";
import { Show, SimpleShowLayout, TextField, DateField, ImageField } from "react-admin";

const BRShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="videoId" />
      <TextField source="link" />
      <DateField showTime source="startDate" />
      <ImageField source="previews.l.link" />
      <DateField showTime label="Дата создания" source="createdAt" />
      <DateField showTime label="Дата редактирования" source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export default BRShow;
