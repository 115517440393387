// @flow
// in src/posts.js
import React from "react";
import { Edit, SimpleForm, TextInput, DateTimeInput } from "react-admin";

const PostTitle = ({ record }) => {
  return <span>{record ? `"${record.name}"` : ""}</span>;
};

const EditBroadcast = (props) => (
  <Edit title={<PostTitle />} {...props}>
    <SimpleForm>
      <TextInput label="Название" source="name" />
      <TextInput label="Описание" multiline source="description" />
      <DateTimeInput label="Начало" source="startDate" />
    </SimpleForm>
  </Edit>
);

export default EditBroadcast;
