// @flow
// in src/App.js
import React from "react";
import { Admin, Resource } from "react-admin";
import restProvider from "./restProvider";
import authProvider from "./authProvider";
import addUploadFeature from "./AddDataProvider";

import LoginPage from "./LoginPage";
import UserShow from './usres/UserShow'
import { VideosList, VideoShow, EditVideo, CreateVideo } from "./videos";
import { BroadcastsList, BroadcastsShow, BroadcastsEdit, BroadcastsCreate } from "./broadcasts";
import { CouponList, CouponShow, CouponEdit, CouponCreate } from "./coupons";
import { OrdersList, OrdersShow } from "./orders";
import { GoodList, GoodEdit, GoodCreate } from "./goods";
import { ScenarioList, ScenarioShow, ScenarioEdit } from "./scenario";
import { PlaylistList, PlaylistEdit, PlaylistCreate, PlaylistShow } from "./playlists";
import { PersonsList, PersonsEdit, PersonsCreate } from './persons'
import { BanerList, BanerEdit, BanerCreate } from './baner'
import { RecommendedList, RecommendedEdit, RecommendedCreate, RecommendedShow } from './RecommendedPlaylists'
import { ContestList, ContestShow, ContestEdit, ContestCreate } from './contests';
import { CoursesCreate, CoursesEdit, CoursesList, CoursesShow } from './courses';
import  { FeatureBlocksList, BlocksEdit, BlocksCreate } from './featureBlocks';
import { LessonsList, LessonsEdit, LessonsCreate, LessonShow } from './lessons'
import { HMList, HMEdit } from "./homeworks"

import RatingList from './ratings/List'



import UsersList from "./usres/UsersList";

const App = () => (
  <Admin dataProvider={addUploadFeature(restProvider)} authProvider={authProvider} loginPage={LoginPage}>
    <Resource
      name="admin/videos"
      options={{ label: "Видео" }}
      list={VideosList}
      show={VideoShow}
      edit={EditVideo}
      create={CreateVideo}
    />
    <Resource
      name="admin/broadcasts"
      options={{ label: "Трансляции" }}
      list={BroadcastsList}
      show={BroadcastsShow}
      edit={BroadcastsEdit}
      create={BroadcastsCreate}
    />
    <Resource
      name="admin/scenarios"
      options={{ label: "Сценарии и рецензии" }}
      list={ScenarioList}
      show={ScenarioShow}
      edit={ScenarioEdit}
    />
    <Resource name="admin/users" options={{ label: "Пользователи" }} list={UsersList} show={UserShow} />
    <Resource
      name="admin/coupon"
      options={{ label: "Промокоды" }}
      list={CouponList}
      show={CouponShow}
      edit={CouponEdit}
      create={CouponCreate}
    />
    <Resource name="admin/goods" options={{ label: "Товары" }} list={GoodList} edit={GoodEdit} create={GoodCreate} />
    <Resource name="admin/orders" options={{ label: "Сделки" }} list={OrdersList} show={OrdersShow} />
    <Resource name="admin/playlists" options={{ label: "Плейлисты" }} list={PlaylistList} edit={PlaylistEdit} create={PlaylistCreate} show={PlaylistShow} />
    <Resource name="admin/contests" options={{ label: "Конкурсы" }} list={ContestList} edit={ContestEdit} create={ContestCreate} show={ContestShow} />
    <Resource name="admin/courses" options={{ label: "Курсы" }} create={CoursesCreate} edit={CoursesEdit} list={CoursesList} show={CoursesShow}></Resource>
    <Resource name="admin/feature-blocks" options={{ label: "Фичи курсов" }} create={BlocksCreate} edit={BlocksEdit} list={FeatureBlocksList}></Resource>
    <Resource name="admin/lessons" options={{ label: "Уроки" }} create={LessonsCreate} edit={LessonsEdit} list={LessonsList} show={LessonShow} ></Resource>
    <Resource name="admin/homeworks" options={{ label: "Домашние работы" }} list={HMList} edit={HMEdit}></Resource>
    <Resource name="admin/recommended-playlists" options={{ label: "Рекомен. Плейлисты" }} list={RecommendedList} edit={RecommendedEdit} create={RecommendedCreate} show={RecommendedShow} />
    <Resource name="admin/playlists/order" />
    <Resource name="admin/persons" options={{ label: "Персоны" }} list={PersonsList} edit={PersonsEdit} create={PersonsCreate} />
    <Resource name="admin/baners" options={{ label: "Банер" }} list={BanerList} edit={BanerEdit} create={BanerCreate} />
    <Resource name="admin/ratings" options={{ label: "Рейтинги" }} list={RatingList} />
  </Admin>
);

export default App;
