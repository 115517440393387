
// @flow
import React from "react";
import { List, Datagrid, TextField, DateField, BooleanField, ShowButton, Filter, TextInput } from "react-admin";

const PostFilter = (props) => (
  <Filter {...props} fullWidth>
    <TextInput label="имя" source="searchName" alwaysOn fullWidth resettable />
    <TextInput label="email" source="email" alwaysOn fullWidth resettable />
  </Filter>
);

/* we use perPage={10000} because we don't want to use pagination here. */
const UserList = (props) => (
  <List title="Все Пользователи" {...props} perPage={30} bulkActionButtons={null} filters={<PostFilter {...props} />}>
    <Datagrid>
      <TextField label="Имя" source="name" sortable={false} />
      <TextField source="email" sortable={false} />
      <TextField source="phoneNumber" sortable={false}></TextField>
      <BooleanField label="Куплен билет на таймпад" source="payedOnTimePad" sortable={false} />
      <TextField label="Роль" source="role" sortable={false} />
      <DateField label="Дата установки промокода" source="promoCodeSetDate" sortable={false} />
      <DateField showTime label="Дата создания" source="createdAt" sortable={false} />
      <DateField showTime label="Дата редактирования" source="updatedAt" sortable={false} />
      <ShowButton></ShowButton>
    </Datagrid>
  </List>
);

export default UserList

/*
_id: Types.ObjectId;
email: string;
name: string;
payedOnTimePad: boolean;
role: string;
accessLevel: string;
promoCodeSetDate?: Date;
createdAt: Date;
updatedAt: Date;
authType: string;
*/
