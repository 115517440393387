// @flow
import React from "react";
import { required, ReferenceInput, SelectInput, FormDataConsumer, TextInput, SimpleForm } from "react-admin";
import { BooleanInput, ImageInput, ImageField, ReferenceArrayInput, SelectArrayInput } from "react-admin";
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';


export const PlaylistForm = (props /*: Object */) => (
  <SimpleForm {...props} redirect="show" >
    <TextInput label="Название" source="name" validate={[required()]} fullWidth />
    <TextInput label="Url трейлера" source="trailer" fullWidth />
    <TextInput
      label="Описание анонса (225 знаков)"
      multiline
      source="announceDescription"
      parse={announceDescriptionParser}
      validate={[required()]}
      length={5}
      fullWidth
    />
    <RichTextInput label="Описание плейлиста"
      source="description" validate={[required()]}
      options={{ formats: ["align", "list", "header", "direction", "underline", "link", "bold", "Italic"] }}
    />
    <ImageInput label="Картинка" source="img" validate={[required()]} accept="image/jpeg" maxSize={300000} labelSingle="Одно изображение в формате JPEG до 300 КБ">
      <ImageField source="img" title="изображение" />
    </ImageInput>
    <GoodId></GoodId>
    <ReferenceInput label="Порядковый номер" source="order" reference="admin/playlists/order">
      <SelectInput optionText="order" />
    </ReferenceInput>
    <ReferenceArrayInput label="Видео" source="videosIds" reference="admin/videos" perPage={200} fullWidth sort={{ field: 'name', order: 'ASC' }}>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput label="Рекомендованные видео" source="additionalVideosIds" perPage={200} reference="admin/videos" fullWidth sort={{ field: 'name', order: 'ASC' }}>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput label="Преподаватели" source="teachersIds" perPage={200} reference="admin/persons" fullWidth>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <BooleanInput label="Рейтинг" source="withRating" />
    <BooleanInput label="Мираж плейлист" source="isMiraz" />
    <ArrayInput label="Теги" source="tags" defaultFieldValue="тег" >
      <SimpleFormIterator>
        <TextInput label="тег" />
      </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput label="Партнеры" source="partners">
      <SimpleFormIterator>
        <TextInput source="name" label="Название" />
        <TextInput source="description" label="Описание" />
      </SimpleFormIterator>
    </ArrayInput>
    <BooleanInput label="Опубликовать" source="isPublished" />
  </SimpleForm>
)

export const GoodId = () => {
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        // if (formData && formData.paid) {
        return (
          <ReferenceInput {...rest} label="Товар" source="goodId" reference="admin/goods" allowEmpty emptyValue={null}>
            <SelectInput optionText="name" resettable fullWidth />
          </ReferenceInput>
        )
      }
        // return undefined
      }
    </FormDataConsumer>
  )
}

const announceDescriptionParser = (val) => {
  if (val?.length >= 225) {
    return val.substring(0, 255)
  }

  return val
}