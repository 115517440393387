// @flow
import React from "react";
import { SimpleForm, TextInput, ArrayInput,ReferenceInput, SelectInput, SimpleFormIterator, Edit, Create, required} from "react-admin";

const PostTitle = ({ record }) => {
  return <span>Курс {record ? `"${record.course.name}"` : ''}</span>;
};

export const BlocksEdit = (props /*: Object */) => (
  <Edit title={<PostTitle />} {...props}>
    <Form {...props} />
  </Edit>
);

export const BlocksCreate = (props /*: Object */) => (
  <Create title={"Дополнение списка фичей"} {...props}>
    <Form {...props} />
  </Create>
);

const Form = (props /*: Object */) => (
  <SimpleForm {...props}>
    <ReferenceInput label="Курс" source="courseId" reference="admin/courses" validate={[required()]}>
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>

    <ArrayInput label="дешевый курс" source="cheap">
      <SimpleFormIterator>
          <TextInput label="доп фича" source="" fullWidth />
      </SimpleFormIterator>
    </ArrayInput>

    <ArrayInput label="дорогой курс"source="rich">
      <SimpleFormIterator>
          <TextInput label="доп фича" source="" fullWidth />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);
