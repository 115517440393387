// @flow
// in src/posts.js
import React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";

const PostTitle = ({ record }) => {
  return <span>{record ? `"${record.name}"` : ""}</span>;
};

const EditVideo = (props) => (
  <Edit title={<PostTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" label="Имя" fullWidth />
      <TextInput source="description" multiline label="описание" fullWidth />
      <TextInput source="link" label="ссылка" fullWidth />
      <BooleanInput label="Отображать чат?" source="isBroadcast" />
    </SimpleForm>
  </Edit>
);

export default EditVideo;
