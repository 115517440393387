// @flow
import React from "react";
import { List, Datagrid, TextField, EditButton, BooleanField, TextInput, Filter } from "react-admin";

const PostFilter = (props) => (
  <Filter {...props} fullWidth>
    <TextInput label="Поиск по имени урока" source="name" alwaysOn fullWidth resettable />
  </Filter>
);

const LessonList = (props /*: Object */) => (
  <List  {...props} perPage={25} bulkActionButtons={null} filters={<PostFilter {...props} />}>
    <Datagrid>
      <TextField label="Название" source="name" sortable={false} />
      <TextField label="Домашнее задание" source="lessonTask.description" sortable={false} />
      <BooleanField label="Вебинар" source="isWebinar"></BooleanField>
      <EditButton />
    </Datagrid>
  </List>
);

export default LessonList