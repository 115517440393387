// @flow
import React from "react";
import { List, Datagrid, TextField, EditButton, BooleanField, UrlField } from "react-admin";

/* we use perPage={10000} because we don't want to use pagination here. */
const BanerList = (props /*: Object */) => (
  <List  {...props} perPage={10000} bulkActionButtons={null}>
    <Datagrid>
      <TextField label="Заголовок" source="header" sortable={false} />
      <TextField label="Подзаголовок" source="subheader" sortable={false} />
      <TextField label="Текст" source="text" sortable={false} />
      <UrlField label="Ссылка" source="link" sortable={false}></UrlField>
      <BooleanField label='Опубликован' source='isPublished' />
      <EditButton />
    </Datagrid>
  </List>
);

export default BanerList