// @flow
import React from "react";
import { Show, SimpleShowLayout, TextField, DateField, NumberField } from "react-admin";

const CouponShow = (props /*: Object */) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="Название" source="name" />
      <TextField label="Код промокода" source="code" />
      <NumberField label="Процент скидки" source="percent" />
      <DateField label="Дата истечения" source="expiryDate" sortable={false} />
      <TextField label="Кол-во использований" source="useCount" />
      <TextField label="Максимальное кол-во использований" source="maxUseCount" />
      <DateField label="Дата создания" source="createdAt" />
      <DateField label="Дата редактирования" source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export default CouponShow;
