// @flow
// in src/posts.js
import React from "react";
import { ShowView, ShowController, SimpleShowLayout, TextField, DateField, SelectField, UrlField } from "react-admin";
import { SCENARIO_STATUSES, SCENARIO_NAMES } from '../consts'

const ScenarioShow = (props) => (
  <ShowController {...props} >
    {controllerProps =>
      <ShowView {...props} {...controllerProps} title={"Сценарий " + controllerProps?.record?.name}>
        <SimpleShowLayout>
          <TextField source="user.email" label="Email пользователя" />
          <TextField source="name" label="Название Сценария" />
          <TextField source="genre" label="Жанр" />
          <SelectField
            label="Консультация"
            source="onlineСonsultation"
            choices={[
              { id: "text", name: "В мессенджере" },
              { id: "video", name: "В скайпе" },
              { id: "none", name: "Не нужна" },
            ]}
          />
          <SelectField
            label="Статус"
            source="status"
            choices={[
              { id: SCENARIO_STATUSES.DONE, name: "Готово" },
              { id: SCENARIO_STATUSES.UPLOADED, name: "Загружено" },
              { id: SCENARIO_STATUSES.WRITING, name: "Пишем" },
              { id: SCENARIO_STATUSES.PAYED, name: "Оплачено" },
            ]}
          />
          <SelectField
            label="Тип"
            source="type"
            choices={SCENARIO_NAMES}
          />
          <UrlField source="link" label="Ссылка на сценарий" />
          {controllerProps?.record?.review && (
            <TextField source="review.name" label="Название рецензии" />
          )}
          {controllerProps?.record?.review && (
            <UrlField source="review.link" label="Ссылка на рецензию" />
          )}
          <TextField source="userData.name" label="Имя" />
          <TextField source="userData.lastName" label="Фамилия" />
          {controllerProps?.record?.userData?.fatherName && (
            <TextField source="userData.fatherName" label="Отчество" />
          )}
          {controllerProps?.record?.userData?.dateOfBirth && (
            <DateField source="userData.dateOfBirth" label="Дата рождения" />
          )}
          {controllerProps?.record?.userData?.city && (
            <TextField source="userData.city" label="Город" />
          )}
          <UrlField source="userData.socialLink" label="Ссылка в соцсетях" />
          <DateField label="Дата создания" source="createdAt" />
          <DateField label="Дата редактирования" source="updatedAt" />
        </SimpleShowLayout>
      </ShowView>
    }
  </ShowController>
);

export default ScenarioShow;
