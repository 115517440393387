import React from "react";
import {
  ImageInput, ImageField, SelectInput, ReferenceArrayInput, SelectArrayInput, ArrayInput, SimpleFormIterator,
  SimpleForm, TextInput, BooleanInput, Edit, Create, required
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';

// Lesson = {
// _id: ObjectId;
// name: string;
// description: string;
// announceDescription?: string;
// contentIds?: string[];
// teachersIds: ObjectId[];
// lessonTask: LessonTask;
// isWebinar: boolean;
// img: string;
// video: string;
// homework?: Homework;

// };


export const Form = (props /*: Object */) => (
  <SimpleForm {...props} redirect="show" >
    <TextInput label="Название" source="name" validate={[required()]} fullWidth />
    <TextInput label="Анонс" source="announceDescription" fullWidth multiline />
    <RichTextInput label="Описание урока"
      source="description" validate={[required()]}
      options={{ formats: ["align", "list", "header", "direction", "underline", "link", "bold", "Italic"] }}
    />
    <ImageInput label="Картинка" source="img" validate={[required()]} accept="image/jpeg" maxSize={300000} labelSingle="Одно изображение в формате JPEG до 300 КБ">
      <ImageField source="img" title="изображение" />
    </ImageInput>
    <TextInput source="video" fullWidth></TextInput>
    <ArrayInput source="links">
      <SimpleFormIterator>
        <TextInput source="name" label="Имя" validate={[required()]} fullWidth></TextInput>
        <TextInput source="link" label="Ссылка" fullWidth validate={[required()]} />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput label="Домашнее задание" source="lessonTask.description" multiline />
    <BooleanInput label="Вебинар" source="isWebinar"></BooleanInput>
    <RichTextInput label="Материалы урока"
      source="content"
      options={{ formats: ["align", "list", "header", "direction", "underline", "link", "bold", "Italic"] }}
    />
  </SimpleForm>
)

export const LessonsEdit = (props /*: Object */) => (
  <Edit {...props} title="Редактирование Урока">
    <Form {...props} />
  </Edit>
);

export const LessonsCreate = (props /*: Object */) => (
  <Create {...props} title="Создание Урока">
    <Form {...props} />
  </Create>
);