// @flow
// in src/posts.js
import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  SelectInput,
  FileField,
} from "react-admin";
import { SCENARIO_STATUSES } from "../consts"

const PostTitle = ({ record }) => {
  return <span>{record ? `"${record.name}"` : ""}</span>;
};

const EditScenario = props => (
  <Edit title={<PostTitle />} {...props}>
    <SimpleForm>
      <TextInput source="review.name" label="Имя рецензии" />
      <FileInput source="file" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
      <SelectInput
        label="Статус"
        source="status"
        choices={[
          { id: SCENARIO_STATUSES.DONE, name: "Готово" },
          { id: SCENARIO_STATUSES.WRITING, name: "Пишем" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export default EditScenario;
