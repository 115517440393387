// @flow
import axios from "axios";
import { Preview } from "./courses/Forms";
// import imageCompression from 'browser-image-compression';


const addUploadFeature = (requestHandler) => async (type, resource, params) => {
  if (type === "CREATE" || type === "UPDATE") {
    if (resource === "admin/scenarios") {
      if (isFile(params.data.file)) {
        const url = await uploadFile(params.data.file, "reviews");
        return requestHandler(type, resource, {
          ...params,
          data: {
            ...params.data,
            url,
          },
        });
      }
    }

    if (resource === "admin/playlists") {
      let imgUrl = params.data.img;
      if (isFile(params.data.img)) {
        imgUrl = await uploadFile(params.data.img, "playlists-images");
      }
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          videos: null,
          additionalVideos: null,
          teachers: null,
          img: imgUrl,
        },
      });
    }

    if (resource === "admin/contests") {
      let imgUrl = params.data.img;
      if (isFile(params.data.img)) {
        imgUrl = await uploadFile(params.data.img, "playlists-images");
      }
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          img: imgUrl,
        },
      });
    }



    if (resource === "admin/persons" || resource === "admin/lessons") {
      let imgUrl = params.data.img;
      if (isFile(params.data.img)) {
        imgUrl = await uploadFile(params.data.img, "playlists-images");
      }
      console.log("imgUrl", imgUrl)
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          img: imgUrl,
        },
      });
    }

    if (resource === "admin/courses") {
      let announceImgUrl = params.data.announce.img;
      let previewImgUrl = params.data.preview.img;
      if (isFile(announceImgUrl)) {
        announceImgUrl = await uploadFile(announceImgUrl, "playlists-images");
      }
      if (isFile(previewImgUrl)) {
        previewImgUrl = await uploadFile(previewImgUrl, "playlists-images");
      }
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          announce: {
            ...(params?.data?.announce || {}),
            img: announceImgUrl
          },
          preview: {
            ...(params.data.preview || {}),
            img: previewImgUrl
          }
        },
      });
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export const isFile = (file) => file && !(file instanceof String) && file.rawFile instanceof File

export const uploadFile = async (file /*: { rawFile: File, title: string } */, type /*: string */) => {
  const form = new FormData();
  console.log(file)
  form.append("doc", file.rawFile, file.title);
  form.append("type", type);

  const res = await axios({
    method: "post",
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: "/upload/file",
    data: form,
    withCredentials: true
  });
  console.log("res.data", res.data)
  return res.data.data.url;
};

// const minimizeFile = async ({ rawFile, ...rest }, { maxSizeMB = 0.2, maxWidthOrHeight = 1000 }) => {
//   console.log(`originalFile size ${rawFile.size / 1024 / 1024} MB`);

//   const compressedFile = await imageCompression(rawFile, {
//     maxSizeMB,
//     maxWidthOrHeight,
//     fileType: "jpeg"
//   })

//   console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
//   return blobToFile(compressedFile, rawFile.name)
// }

// function blobToFile(Blob, fileName) {
//   console.log(Blob)
//   return new File([Blob], fileName, { type: Blob.type, lastModified: Date.now() });
// }



export default addUploadFeature;
