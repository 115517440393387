// @flow
import React from "react";
import ReactDOM from "react-dom";

// import "react-flexbox-grid/dist/react-flexbox-grid.css";
// import "normalize.css";

import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
