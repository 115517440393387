// @flow
import React from "react";
import { List, Datagrid, TextField, EditButton, Filter, TextInput, FunctionField } from "react-admin";

const PostFilter = (props) => (
  <Filter {...props} fullWidth>
    <TextInput label="Поиск по имени дз" source="name" alwaysOn fullWidth />
  </Filter>
);

const LessonList = (props /*: Object */) => (
  <List  {...props} perPage={25} filters={<PostFilter {...props} />} bulkActionButtons={null} >
    <Datagrid>
      <TextField label="Название" source="name" sortable={false} />
      <TextField label="Пользователь" source="user.email" sortable={false} />
      <TextField label="Урок" source="lesson.name"></TextField>
      <TextField label="Курс" source="course.name"></TextField>
      <FunctionField label="ДЗ" render={(record /*: Object */) => {
        if (!record.uploadUrl) return ""
        return <a href={record.uploadUrl} target="blank">ссылка</a>
      }} />
      <FunctionField label="Ответ" render={(record /*: Object */) => {
        if (!record.response) return ""
        return <a target="blank" href={record.response}>{record.responseName || "ссылка на ответ"}</a>
      }} />
      <EditButton />
    </Datagrid>
  </List>
);

export default LessonList