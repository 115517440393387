// @flow
import React from "react";
import { List, Datagrid, TextField, EditButton, BooleanField, FunctionField } from "react-admin";

// export type Course = {
//   _id: ObjectId;
//   name: string;
//   description: string;
//   lessonsBlocks: { name: string; lessonsIds: ObjectId[] }[];
//   lessonsTasksIds: ObjectId[];
//   goodId: ObjectId;
//   preview: CoursePreview;
//   announce: CourseAnnounce;
//   teachersIds: ObjectId[];
//   duration: number;
//   isPublished: boolean;
//   baner?: {
//     sale: number;
//     name: string;
//     description?: string;
//   };
// };

const CourseList = (props /*: Object */) => (
  <List  {...props} perPage={10000} bulkActionButtons={null}>
    <Datagrid>
      <TextField label="Название" source="name" sortable={false} />
      <FunctionField label="Цена" render={(record /*: Object */) => record?.good?.price && `₽ ${record.good.price / 100}`} sortable={false} />
      <FunctionField label="Цена без ДЗ" render={(record /*: Object */) => record?.lightGood?.price && `₽ ${record.lightGood.price / 100}`} sortable={false} />
      <TextField label="Продолжительность" source="duration" sortable={false} />
      <TextField label="Банер" source="baner.name" sortable={false} />
      <BooleanField label='isPublished' source='isPublished' />
      <EditButton />
    </Datagrid>
  </List>
);

export default CourseList