// @flow
import React from "react";
import { List, Datagrid, TextField, EditButton, DeleteButton } from "react-admin";

/* we use perPage={10000} because we don't want to use pagination here. */
const FeatureBlocks = (props /*: Object */) => (
  <List title="Все блоки фич" {...props} perPage={10000} bulkActionButtons={null}>
    <Datagrid>
      <TextField label="Название" source="course.name" sortable={false} />
      <EditButton />
      <DeleteButton></DeleteButton>
    </Datagrid>
  </List>
);

export default FeatureBlocks