export const GOOD_TYPES = {
  PLAY_LIST: "PLAY_LIST",
  SCENARIO_REVIEW: "SCENARIO_REVIEW",
  DEFEND_SCENARIO: "DEFEND_SCENARIO",
  COURSE: "COURSE",
};

export const GOODS_NAMES = [
  { id: GOOD_TYPES.PLAY_LIST, name: "Плейлист" },
  { id: GOOD_TYPES.SCENARIO_REVIEW, name: "Рецензия на сценарий" },
  { id: GOOD_TYPES.DEFEND_SCENARIO, name: "Депонирование" },
  { id: GOOD_TYPES.COURSE, name: "Курс" },
]

export const SCENARIO_TYPES = {
  SHORT_FILM: "SHORT_FILM",
  WEB_SERIES: "WEB_SERIES",
  FEATURE_FILM: "FEATURE_FILM",
  SERIES_PILOT: "SERIES_PILOT",
  SYNOPSIS: "SYNOPSIS",
  EDITORIAL_OPINION: "EDITORIAL OPINION",
  EDITORIAL_SUPPORT: "EDITORIAL_SUPPORT",
};

export const SCENARIO_NAMES = [
  { id: SCENARIO_TYPES.SHORT_FILM, name: "Короткий метр" },
  { id: SCENARIO_TYPES.WEB_SERIES, name: "Веб Сериал" },
  { id: SCENARIO_TYPES.FEATURE_FILM, name: "Полный метр" },
  { id: SCENARIO_TYPES.SERIES_PILOT, name: "Сериал (Пилот)" },
  { id: SCENARIO_TYPES.EDITORIAL_OPINION, name: "Редакторское заключение" },
  { id: SCENARIO_TYPES.SYNOPSIS, name: "Синопсис" },
  {id: SCENARIO_TYPES.EDITORIAL_SUPPORT, name: "редакторское сопровождение"}
]

export const SCENARIO_STATUSES = {
  PAYED: "PAYED",
  UPLOADED: "UPLOADED",
  WRITING: "WRITING",
  DONE: "DONE",
};

export const RECOMMENDED_PLAYLISTS_TYPES = {
  MAIN_PAGE: "MAIN_PAGE",
  SCENARIO_PAGE: "SCENARIO_PAGE",
  ACCOUNT_USER: "ACCOUNT_USER",
  ACCOUNT_PAYED: "ACCOUNT_PAYED",
  ACCOUNT_REVIEWS: "ACCOUNT_REVIEWS",
};

export const RECOMMENDED_PLAYLISTS_NAMES = [
  { id: RECOMMENDED_PLAYLISTS_TYPES.MAIN_PAGE, name: "Топ видео" },
  { id: RECOMMENDED_PLAYLISTS_TYPES.SCENARIO_PAGE, name: "Страница загрузки сценария" },
  { id: RECOMMENDED_PLAYLISTS_TYPES.ACCOUNT_PAYED, name: "Оплачено" },
  { id: RECOMMENDED_PLAYLISTS_TYPES.ACCOUNT_REVIEWS, name: "Рецензии в ЛК" },
  { id: RECOMMENDED_PLAYLISTS_TYPES.ACCOUNT_USER, name: "Профиль в ЛК" },
]

export const CONTESTS_FORMATS = {
  SHORT_FILM: "SHORT_FILM",
  FEATURE_FILM: "FEATURE_FILM",
  SERIES_PILOT: "SERIES_PILOT",
  SERIES: "SERIES",
  DOCUMENTARY: "DOCUMENTARY",
  ANIMATION: "ANIMATION",
  CREATIVE: "CREATIVE",
};

export const CONTESTS_FORMATS_NAMES = [
  { id: CONTESTS_FORMATS.SHORT_FILM, name: "Короткий метр" },
  { id: CONTESTS_FORMATS.SERIES, name: "Сериал" },
  { id: CONTESTS_FORMATS.SERIES_PILOT, name: "Сериал (Пилот)" },
  { id: CONTESTS_FORMATS.FEATURE_FILM, name: "Полный метр" },
  { id: CONTESTS_FORMATS.DOCUMENTARY, name: "Документальный фильм" },
  { id: CONTESTS_FORMATS.ANIMATION, name: "Анимация" },
  { id: CONTESTS_FORMATS.CREATIVE, name: "Реклама" },
]