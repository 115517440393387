// @flow
import React from "react";
import { List, Datagrid, TextField, DateField, EditButton, FunctionField } from "react-admin";

/* we use perPage={10000} because we don't want to use pagination here. */
const CouponList = (props /*: Object */) => (
  <List title="Все промокоды" {...props} perPage={10000} bulkActionButtons={null}>
    <Datagrid>
      <TextField label="Название" source="name" sortable={false} />
      <FunctionField label="Процент скидки" render={(record /*: Object */) => `${parseInt(record.percent)}%`} />
      <TextField label="Промокод" source="code" sortable={false} />
      <DateField label="Дата истечения" source="expiryDate" />
      <TextField label="Кол-во использований" source="useCount" />
      <TextField label="Максимальное кол-во использований" source="maxUseCount" />
      <EditButton />
    </Datagrid>
  </List>
);

export default CouponList

/*
  _id: Types.ObjectId;
  expiryDate: Date;
  name: string;
  code: string;
  percent: number;
  createdAt: Date;
*/
