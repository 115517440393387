// @flow
import React from "react";
import { SimpleForm, TextInput, Edit, Create, required, ImageInput, ImageField } from "react-admin";

export const FormEdit = (props /*: Object */) => (
  <Edit {...props}>
    <Form {...props} />
  </Edit>
);

export const FormCreate = (props /*: Object */) => (
  <Create {...props}>
    <Form {...props} />
  </Create>
);

const Form = (props /*: Object */) => (
  <SimpleForm {...props} redirect="list">
    <TextInput label="Название" source="name" validate={[required()]} />
    <TextInput label="Описание" source="description" multiline validate={[required()]} />
    <ImageInput label="Картинка" source="img" validate={[required()]} accept="image/jpeg" maxSize={300000} labelSingle="Одно изображение в формате JPEG до 300 КБ">
      <ImageField source="img" title="изображение" />
    </ImageInput>
  </SimpleForm>
);
