// @flow
import React from "react";
import { PlaylistForm } from "./Forms"
import { Edit } from "react-admin"

const PlaylistEdit = (props /*: Object */) => (
  <Edit {...props} title="Отредактировать плейлист">
    <PlaylistForm {...props}></PlaylistForm>
  </Edit>
);

export default PlaylistEdit

//   name: string;
//   videosIds: Types.ObjectId[];
//   videos?: Video[];
//   goodId?: Types.ObjectId;
//   paid: boolean;
//   trailer: string;
//   duration?: number;
//   img: string;
//   description: string;
//   teachers: string[];
//   partners: string[];
//   tags: string[];
//   order: number;
//   createdAt: Date;
//   updatedAt: Date;
//   isPublished?: boolean;

// "_id" | "videos" | "duration" | "createdAt" | "updatedAt"