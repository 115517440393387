// @flow
import React from "react";
import { List, Datagrid, TextField, DateField, ShowButton, EditButton, BooleanField } from "react-admin";

/* we use perPage={10000} because we don't want to use pagination here. */
const PlaylistList = (props /*: Object */) => {
  return (
    <List title="Все плейлисты" {...props} perPage={10000} bulkActionButtons={null}>
      <Datagrid>
        <TextField label="Название" source="name" />
        <PaidField label='Платный' source='goodId' />
        <TextField label="Номер" source="order" />
        <BooleanField label='Опубликован' source='isPublished' />
        <BooleanField label='Рейтинг' source='withRating' />
        <DateField showTime label="Дата редактирования" source="updatedAt" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const PaidField = ({ source, record = {} }) => <span>{!record[source] ? "" : "Да"}</span>;


export default PlaylistList

// type Playlist = {
//   _id: Types.ObjectId;
//   name: string;
//   videosIds: Types.ObjectId[];
//   videos?: Video[];
//   goodId?: Types.ObjectId;
//   paid: boolean;
//   trailer: string;
//   duration?: number;
//   img: string;
//   description: string;
//   teachers: string[];
//   partners: string[];
//   tags: string[];
//   order: number;
//   createdAt: Date;
//   updatedAt: Date;
//   isPublished?: boolean;
// };

// export type PlaylistAnnounce = Pick<
//   Playlist,
//   "name" | "paid" | "img" | "description" | "tags" | "order"
// >;