// @flow
import React from "react";
import { Create } from "react-admin";
import { PlaylistForm } from "./Forms"

const PlaylistCreate = (props /*: Object */) => (
  <Create {...props} title="Создать плейлист">
    <PlaylistForm {...props}></PlaylistForm>
  </Create>
);

export default PlaylistCreate