// @flow
import React from "react";
import { List, Datagrid, TextField, DateField, EditButton, BooleanField, FunctionField, SelectField } from "react-admin";
import { CONTESTS_FORMATS_NAMES } from '../consts'

/* we use perPage={10000} because we don't want to use pagination here. */
const ContestList = (props /*: Object */) => (
  <List title="Все конкурсы" {...props} perPage={25} bulkActionButtons={null}>
    <Datagrid>
      <TextField label="Название" source="name" sortable={false} />
      <DateField label="Дата начала" source="dateStart" />
      <DateField label="Дата окончания" source="dateFinish" />
      <FunctionField label="Внешний конкурс" render={(record /*: Object */) => record?.externalLink && "Да"} />
      <TextField label="Цена" source="price" />
      <SelectField
        label="Формат"
        source="format"
        choices={CONTESTS_FORMATS_NAMES}
      />
      <BooleanField label="Первая позиция" source="isTop" />
      <BooleanField label="Вторая позиция" source="isTop2" />
      <BooleanField label="Интервал" source="isRange" />
      <BooleanField label="Опубликован" source="isPublished" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ContestList

