// @flow
import React from "react";
import { List, Datagrid, TextField, DateField, NumberField, ShowButton, SelectField, FunctionField } from "react-admin";
const PAYMENT_TYPES = {
  NEW: "NEW",
  INIT: "INIT",
  AUTHORIZED: "AUTHORIZED",
  COMPLETE: "COMPLETE",
  REJECTED: "REJECTED",
};

/* we use perPage={10000} because we don't want to use pagination here. */
export default (props /*: Object */) => (
  <List title="Все сделки" {...props} perPage={20} bulkActionButtons={null}>
    <Datagrid>
      <TextField label="Товар" source="good.name" />
      <FunctionField label="Цена" render={(record /*: Object */) => `₽ ${record.price / 100}`} />
      <SelectField
        source="status"
        choices={[
          { id: PAYMENT_TYPES.NEW, name: "Новый" },
          { id: PAYMENT_TYPES.INIT, name: "Инициирован" },
          { id: PAYMENT_TYPES.AUTHORIZED, name: "Авторизован" },
          { id: PAYMENT_TYPES.COMPLETE, name: "Успешен" },
          { id: PAYMENT_TYPES.REJECTED, name: "Ошибка" },
        ]}
      />
      <TextField source="user.email" label="email пользователя" />
      <NumberField label="Процент скидки" source="coupon.percent" />
      <DateField showTime label="Дата обновления" source="updatedAt" />
      <ShowButton />
    </Datagrid>
  </List>
);

/*
  _id: Types.ObjectId;
  price: number;
  goodId: Types.ObjectId;
  status: string;
  userId: User["_id"];
  paymentURL?: string;
  receipt: object;
  paymentType: string;
  paymentProviderId?: string;
  createdAt: Date;
  updatedAt: Date;
  errorMessage?: string;
  couponId?: Types.ObjectId;
*/
