// @flow
import React from "react";
import { List, Datagrid, TextField, DateField, ShowButton, SelectField, EditButton } from "react-admin";
import { SCENARIO_STATUSES, SCENARIO_NAMES } from '../consts'

/* we use perPage={10000} because we don't want to use pagination here. */
const SceanriosList = (props) => (
  <List title="Все сценарии" {...props} perPage={10000} bulkActionButtons={null}>
    <Datagrid>
      <TextField source="user.email" label="Email пользователя" />
      <SelectField
        label="Статус"
        source="status"
        choices={[
          { id: SCENARIO_STATUSES.DONE, name: "Готово" },
          { id: SCENARIO_STATUSES.UPLOADED, name: "Загружено" },
          { id: SCENARIO_STATUSES.PAYED, name: "Оплачено" },
          { id: SCENARIO_STATUSES.WRITING, name: "Пишем" },
        ]}
      />
      <SelectField
        label="Тип"
        source="type"
        choices={SCENARIO_NAMES}
      />
      <DateField label="Дата создания" source="createdAt" />
      <DateField label="Дата редактирования" source="updatedAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default SceanriosList;