// @flow
import React from "react";
import { Login, LoginForm } from "ra-ui-materialui";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  login: {
    main: {
      backgroundImage: "url(https://source.unsplash.com/1600x900/?video) !important",
    },
  },
};

const CustomLogin = (props) => <Login loginForm={<LoginForm />} {...props} />;

export default withStyles(styles.login)(CustomLogin);
