// @flow
// in src/posts.js
import React from "react";
import { Create, SimpleForm, TextInput, BooleanInput } from "react-admin";

const PostTitle = ({ record }) => {
  return <span>Загрузить видео</span>;
};

const CreateVideo = (props) => (
  <Create title={<PostTitle />} {...props} >
    <SimpleForm redirect="show">
      <TextInput source="name" label="Имя" fullWidth />
      <TextInput source="description" multiline label="описание" fullWidth />
      <TextInput source="videoId" label="videoId" fullWidth />
      <BooleanInput label="Отображать чат?" source="isBroadcast" />
    </SimpleForm>
  </Create>
);

export default CreateVideo;
