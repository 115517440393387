// @flow
import React from "react";
import {
  SimpleForm, TextInput, FormDataConsumer, DateInput, minValue,
  NumberInput, Edit, Create, required, BooleanInput, ImageInput, ImageField, SelectInput
} from "react-admin";
import { CONTESTS_FORMATS_NAMES } from '../consts'
import RichTextInput from 'ra-input-rich-text';
import { endOfDay, startOfDay, endOfMonth } from 'date-fns'

export const ContestEdit = (props /*: Object */) => (
  <Edit {...props} transform={getEndDate}>
    <Form {...props} />
  </Edit>
);

export const ContestCreate = (props /*: Object */) => (
  <Create {...props} transform={getEndDate}>
    <Form {...props} />
  </Create>
);

const Form = (props /*: Object */) => (
  <SimpleForm {...props} redirect="show">
    <TextInput label="Название" source="name" fullWidth validate={[required()]} />
    <ImageInput label="Картинка" source="img" validate={[required()]} accept="image/jpeg" maxSize={200000} labelSingle="Одно изображение в формате JPEG до 200 КБ">
      <ImageField source="img" title="изображение" />
    </ImageInput>
    <RichTextInput label="Описание конкурса"
      source="description" validate={[required()]}
      options={{ formats: ["align", "list", "header", "direction", "underline", "link", "bold", "Italic"] }}
    />
    <TextInput label="Ссылка на внешний конкурс" source="externalLink" fullWidth resettable />
    <BooleanInput label="Интервал" source="isRange" fullWidth />
    <FormDataConsumer>
      {({ formData }) => {
        if (formData && !formData.isRange) {
          return (
            <DateInput label="Дата начала" source="dateStart" parse={startOfADayParse} validate={[required()]} fullWidth />
          )
        }
        return <>
          <DateInput label="Дата начала" parse={startOfADayParse} source="dateStart" validate={[required()]} fullWidth />
          <DateInput label="Дата окончания" parse={endOfADayParse} source="dateFinish" fullWidth validate={[required()]} />
        </>
      }}
    </FormDataConsumer>
    <SelectInput
      label="Формат"
      source="format"
      choices={CONTESTS_FORMATS_NAMES}
      allowEmpty
      resettable
    />
    <NumberInput label="Цена" source="price" validate={[minValue(1)]} fullWidth />
    <BooleanInput label="Первая позиция" source="isTop" fullWidth />
    <BooleanInput label="Вторая позиция" source="isTop2" fullWidth />
    <BooleanInput label="Опубликован" source="isPublished" fullWidth />
  </SimpleForm>
);

const startOfADayParse = (date) => startOfDay(new Date(date))
const endOfADayParse = (date) => endOfDay(new Date(date))

const getEndDate = (values) => {
  if (!values.isRange) {
    return { ...values, dateFinish: endOfMonth(new Date(values.dateStart)) }
  }
  return values
}

// Contest = {
//   _id: string;
//   name: string;
//   description: string;
//   img: string;
//   dateStart: Date;
//   dateFinish?: Date;
//   price?: number;
//   format: string;
//   externalLink?: string;
//   isTop?: boolean;
//   isTop2?: boolean;
//   isPublished: boolean;
// };


