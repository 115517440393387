// @flow
import React from "react";
import {
  Show, SimpleShowLayout, TextField, ImageField,
  RichTextField, ArrayField, BooleanField, Datagrid
} from "react-admin";

const ItemShow = (props /*: Object */) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="Название" source="name" fullWidth />
      <TextField label="Анонс" source="announceDescription" fullWidth multiline />
      <RichTextField label="Описание урока"
        source="description"
      />
      <ImageField source="img" title="изображение" />
      <TextField source="video" ></TextField>
      <ArrayField source="links">
        <Datagrid>
          <TextField source="name" label="Имя"></TextField>
          <TextField source="link" label="Ссылка" />
        </Datagrid>
      </ArrayField>
      <TextField label="Описание ДЗ" source="lessonTask.description" multiline />
      <RichTextField label="Материалы урока"
        source="content"
      />
      <BooleanField label="Вебинар" source="isWebinar"></BooleanField>
    </SimpleShowLayout>
  </Show>
);

export default ItemShow;

