// @flow
import React from "react";
import { SimpleForm, Edit, Create, required, SelectInput, ReferenceArrayInput, SelectArrayInput } from "react-admin";
import { RECOMMENDED_PLAYLISTS_NAMES } from '../consts'

export const FormEdit = (props /*: Object */) => (
  <Edit {...props}>
    <Form {...props} />
  </Edit>
);

export const FormCreate = (props /*: Object */) => (
  <Create {...props}>
    <Form {...props} />
  </Create>
);

const Form = (props /*: Object */) => (
  <SimpleForm {...props} redirect="list">
    <SelectInput
      label="Тип"
      validate={[required()]}
      source="type"
      choices={RECOMMENDED_PLAYLISTS_NAMES}
    />
    <ReferenceArrayInput label="Видео" source="videosIds"
      reference="admin/videos" perPage={200} fullWidth sort={{ field: 'name', order: 'ASC' }}>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
  </SimpleForm>
);