// @flow
// in src/posts.js
import React from "react";
import { Create, SimpleForm, TextInput, DateTimeInput } from "react-admin";

const CreateBroadcast = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Имя" source="name" />
      <TextInput label="Описание" source="description" multiline />
      <TextInput label="videoId с vimeo" source="videoId" />
      <DateTimeInput source="startDate" />
    </SimpleForm>
  </Create>
);

export default CreateBroadcast;
