// @flow
import React from "react";
import { Show, SimpleShowLayout, TextField, BooleanField, ArrayField, Datagrid, DateField, NumberField, SelectField, FunctionField, RichTextField } from "react-admin";
import { CONTESTS_FORMATS_NAMES } from '../consts'

const ItemShow = (props /*: Object */) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="Название" source="name" sortable={false} />
      <FunctionField label="Цена" render={(record /*: Object */) => record?.good?.price && `₽ ${record.good.price / 100}`} sortable={false} />
      <TextField label="Продолжительность" source="duration" sortable={false} />
      <TextField label="Название товара" source="good.name" />
      <TextField label="Дата начала" source="startDate" />
      <FunctionField label="Цена" render={(record /*: Object */) => `₽ ${record.good?.price / 100}`} />
      <SelectField
        label="Формат"
        source="fieldOfStudy"
        choices={CONTESTS_FORMATS_NAMES}
      />
      <ArrayField label="Преподаватели" source="teachers" >
        <Datagrid>
          <TextField label="имя" source="name" />
        </Datagrid>
      </ArrayField>
      <ArrayField label="Отзывы" source="reviews">
        <Datagrid>
          <TextField label="название" source="title" />
          <RichTextField label="text" source="text" />
        </Datagrid>
      </ArrayField>
      <TextField label="Банер" source="baner.name" sortable={false} />
      <TextField label="Банер" source="baner.sale" sortable={false} />
      <TextField label="Банер" source="baner.description" sortable={false} />
      <BooleanField label='isPublished' source='isPublished' />
      <TextField label="Id шаблона в Send Pulse" source="email.tmpId" />
      <TextField label="тема письма" source="email.subject" fullWidth />
    </SimpleShowLayout>
    
  </Show>
);

export default ItemShow;

